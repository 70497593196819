@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
  --primary: #1884ef;
  --primary-dark: #02306b;
  --primary-light: #bfdbfe;
  --primary-hover: #1676d6;
  --primary-hover-light: #a8d1ff;
  --primary-hover-dark: #012654;
  --error: #f04438;
  --error-negative: #b42318;
  --warning: #f79009;
  --error-bg: #fecdca;
  --text: #344054;
  --text-secondary: #98a2b3;
  --dark-bg: #101828;
  --table-header-bg: #e0e0e0;
  --table-header-text: #667085;
  --table-hover-bg: #e0e0e0;
  --light-bg: white;
  --success: #12b76a;
  --badge-active: #15803d;
  --badge-inactive: #991b1b;
  --badge-pending: #7795ff;
  --badge-overdue: #d92d20;
  --badge-cancelled: #98a2b3;
  --badge-paid: #35e35b;
  --badge-nor-draft: #f79009;
  --badge-nor-final: #35e35b;
  --scrollbar-track: #f1f1f1;
  --scrollbar-thumb: color-mix(in srgb, var(--primary) 25%, transparent);
  --scrollbar-thumb-hover: color-mix(in srgb, var(--primary) 50%, transparent);
  --breadcrumb-bg: #f9fafb;
  --breadcrumb-hover: var(--table-header-bg);
  --breadcrumb-border: var(--table-header-bg);
}

html {
  font-family: Roboto, sans-serif;
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover);
}

/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}