.react-datepicker {
    border-radius: 0;
}

/* Add these new styles */
.year-picker-calendar {
    width: auto !important;
    min-width: 120px !important;
    max-width: 280px !important;
}

.react-datepicker-popper {
    z-index: 9999 !important;
}

.react-datepicker__year-wrapper {
    max-width: 180px !important;
    justify-content: center !important;
}

.react-datepicker__month-container {
    float: none !important;
}

.react-datepicker__header {
    padding-top: 0.8em;
}

.react-datepicker__month {
    margin: 0.4em 1em;
}